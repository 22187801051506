import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loginCheckLoading: true,
    isLoggedIn: false,
    info: {},
}

const userDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        setUserData: (state, action) => {
            // Update state properties directly
            state.loginCheckLoading = action.payload.loginCheckLoading;
            state.isLoggedIn = action.payload.isLoggedIn;
            state.info = action.payload.info;
        },
    }
})

export const { setUserData, deleteUserData } = userDataSlice.actions;
export default userDataSlice.reducer;
