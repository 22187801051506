import { configureStore } from '@reduxjs/toolkit'
import userDataSlice from "@/redux/sclices/user-data-slice";
import loadingStatusSlice from "@/redux/sclices/loading-status-slice";

export const store = () => {
    return configureStore({
        reducer: {
            userData: userDataSlice,
            loadingStatus: loadingStatusSlice,
        }
    });
};
