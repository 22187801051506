import {IoMdCheckmarkCircleOutline, IoMdTime} from "react-icons/io";
import {FaRegTimesCircle} from "react-icons/fa";
import {RiRefund2Fill} from "react-icons/ri";

export const TG_TOKEN = 'TG_TOKEN';
export const TOKEN_EXPIRE_TIME = 7; // 7 days
export const WEBSITE_URL = process.env.NEXT_PUBLIC_WEBSITE_BASE_URL; // After deploy change to the website url
export const API_URL = process.env.NEXT_PUBLIC_API_BASE_URL;


// *********************** Forms Variables ***********************
// DS160 Forms
export const CLIENT_FORM = {
    "first_name": "",
    "last_name": "",
    "sex": null,
    "birth_date": null,
    "birth_country": "",
    "birth_state": "",
    "national_id_not_apply": false,
    "us_ss_number_not_apply": false,
    "us_contact_organization_not_apply": false,
    "father_in_us": null,
    "mother_in_us": null,
    "has_relatives_in_us": null,
    "has_travel_companion": null,
    "have_been_in_us": null,
    "have_been_issued_visa": null,
    "has_employed": null,
    "has_served_in_military": null,
};
export const PERSONAL_MARITAL_FORM = {
    "status": null,
    "spouse_surname": "",
    "spouse_given_name": "",
    "spouse_birth_date": null,
    "spouse_birth_country": "",
    "spouse_birth_place": ""
};
export const PERSONAL_LOCATION_FORM = {
    "street_address": "",
    "city": "",
    "state": "",
    "zip_code": "",
    "primary_phone": "",
    "work_phone": "",
    "email": ""
};
export const PERSONAL_SOCIAL_MEDIA_FORM = {
    "platform": "",
    "username": ""
};
export const PERSONAL_NATIONALITY_FORM = {
    "country": "",
    "nation_id": "",
    "us_social_security_number": "",
    "other_nationality": null,
    "other_permanent_resident": null,
    "other_country": ""
};
export const PERSONAL_PASSPORT_FORM = {
    "number": "",
    "issuance_country": "",
    "issuance_city": "",
    "issuance_state": "",
    "issuance_date": null,
    "expiration_date": null
};
export const PERSONAL_US_CONTACT_FORM = {
    "first_name": "",
    "last_name": "",
    "organisation": "",
    "relation": null,
    "street_address": "",
    "city": "",
    "state": "",
    "zip_code": "",
    "email": "",
    "phone": ""
};
export const PERSONAL_FAMILY_FORM = {
    "father_first_name": "",
    "father_last_name": "",
    "mother_first_name": "",
    "mother_last_name": "",
    "parental_citizenship": null
};
export const PERSONAL_RELATIVE_FORM = {
    "first_name": "",
    "last_name": "",
    "relationship": null,
    "citizenship": null
};
export const TRAVEL_INFO_FORM = {
    "general_purpose": null,
    "specific_purpose": null,
    "approximate_arrival_date": "",
    "approximate_departure_date": "",
    "countries_visited": "",
    "last_visit_start_date": null,
    "last_visit_end_date": null,
    "visa_number": "",
    "us_visa_refusal": null,
    "petition_on_client_behalf": null
};
export const TRAVEL_US_LOCATION_FORM = {
    "street_address": "",
    "city": "",
    "state": "",
    "zip_code": ""
};
export const TRAVEL_COMPANION_FORM = {
    "relation": null,
    "first_name": "",
    "last_name": ""
};
export const BG_WORK_FORM = {
    "title": "",
    "street": "",
    "city": "",
    "state": "",
    "zip_code": "",
    "start_date": null,
    "end_date": null,
    "description": "",
    "status": null
};
export const BG_EDUCATION_FORM = {
    "institution_name": "",
    "degree": "",
    "major": "",
    "city": "",
    "country": "",
    "start_date": null,
    "end_date": null
};
export const BG_MILITARY_FORM = {
    "service_branch": "",
    "rank": "",
    "speciality": "",
    "start_date": null,
    "end_date": null
};

// Schedule Forms
export const SCHEDULE_CLIENT_FORM = {
    "first_name": "",
    "last_name": "",
}
export const APPOINTMENT_FORM = {
    "location": "",
    "start_date": null,
    "end_date": null,
    "start_time": "",
    "end_time": "",
    "has_paid_us_visa_account": null,
    "have_account": null,
    "has_paid": null,
    "want_pay_self": null,
}
export const US_VISA_LOGIN_FORM = {
    "username": "",
    "password": ""
}
export const US_VISA_ACCOUNT_FORM = {
    "first_name": "",
    "last_name": "",
    "passport_issuer_country": "",
    "birth_country": "",
    "permanent_residence_country": "",
    "passport_number": "",
    "ds160_number": "",
    "visa_class": null,
    "birth_date": null
}

// Select Box Values
export const SEX_OPTIONS = [
    { id: 1, name: 'Male', value: 1 },
    { id: 2, name: 'Female', value: 2 },
]
export const MARITAL_STATUS_OPTIONS = [
    { id: 1, name: 'Married', value: 1 },
    { id: 2, name: 'Common Law Marriage', value: 2 },
    { id: 3, name: 'Civil Union/Domestic Partnership', value: 3 },
    { id: 4, name: 'Single', value: 4 },
    { id: 5, name: 'Widowed', value: 5 },
    { id: 6, name: 'Divorced', value: 6 },
    { id: 7, name: 'Legally Separated', value: 7 },
    { id: 8, name: 'Other', value: 8 },
]
export const SOCIAL_MEDIA_OPTIONS = [
    { id: 1, name: 'Facebook', value: 1 },
    { id: 2, name: 'Instagram', value: 2 },
    { id: 3, name: 'Twitter', value: 3 },
    { id: 4, name: 'LinkedIn', value: 4 },
    { id: 5, name: 'Snapchat', value: 5 },
    { id: 6, name: 'Pinterest', value: 6 },
    { id: 7, name: 'TikTok', value: 7 },
    { id: 8, name: 'YouTube', value: 8 },
    { id: 9, name: 'Reddit', value: 9 },
    { id: 10, name: 'Tumblr', value: 10 },
    { id: 11, name: 'WhatsApp', value: 11 },
    { id: 12, name: 'Telegram', value: 12 },
    { id: 13, name: 'WeChat', value: 13 },
    { id: 14, name: 'Discord', value: 14 },
    { id: 15, name: 'Viber', value: 15 },
    { id: 16, name: 'Clubhouse', value: 16 },
    { id: 17, name: 'Signal', value: 17 },
    { id: 18, name: 'Line', value: 18 },
    { id: 19, name: 'Quora', value: 19 },
    { id: 20, name: 'Flickr', value: 20 },
    { id: 21, name: 'Mix', value: 21 },
    { id: 22, name: 'Medium', value: 22 },
    { id: 23, name: 'Periscope', value: 23 },
    { id: 24, name: 'Meetup', value: 24 },
    { id: 25, name: 'Twitch', value: 25 }
]
export const RELATIONSHIP_OPTIONS = [
    { id: 1, name: 'Parent (Father/Mother)', value: 1 },
    { id: 2, name: 'Child (Son/Daughter)', value: 2 },
    { id: 3, name: 'Sibling (Brother/Sister)', value: 3 },
    { id: 4, name: 'Spouse (Wife/Husband)', value: 4 },
    { id: 5, name: 'Partner', value: 5 },
    { id: 6, name: 'Grandparent', value: 6 },
    { id: 7, name: 'Grandchild', value: 7 },
    { id: 8, name: 'Aunt', value: 8 },
    { id: 9, name: 'Uncle', value: 9 },
    { id: 10, name: 'Niece', value: 10 },
    { id: 11, name: 'Nephew', value: 11 },
    { id: 12, name: 'Cousin', value: 12 },
    { id: 13, name: 'Friend', value: 13 },
    { id: 14, name: 'Colleague', value: 14 },
    { id: 15, name: 'Boss', value: 15 },
    { id: 16, name: 'Employee', value: 16 },
    { id: 17, name: 'Acquaintance', value: 17 },
    { id: 18, name: 'In-law', value: 18 },
    { id: 19, name: 'Stepparent', value: 19 },
    { id: 20, name: 'Stepchild', value: 20 },
    { id: 21, name: 'Stepsibling', value: 21 },
    { id: 22, name: 'Godparent', value: 22 },
    { id: 23, name: 'Godchild', value: 23 },
    { id: 24, name: 'Mentor', value: 24 },
    { id: 25, name: 'Mentee', value: 25 },
    { id: 26, name: 'Roommate', value: 26 },
    { id: 27, name: 'Ex-Spouse', value: 27 },
    { id: 28, name: 'Foster Parent', value: 28 },
    { id: 29, name: 'Foster Child', value: 29 },
    { id: 30, name: 'Guardian', value: 30 },
    { id: 31, name: 'Ward', value: 31 },
    { id: 32, name: 'Caregiver', value: 32 },
    { id: 33, name: 'Patient', value: 33 }
]
export const CITIZENSHIP_STATUS_OPTIONS = [
    { id: 1, name: 'U.S. Citizen', value: 1 },
    { id: 2, name: 'Permanent Resident (Green Card Holder)', value: 2 },
    { id: 3, name: 'Conditional Resident', value: 3 },
    { id: 4, name: 'Non-Immigrant Visa Holder', value: 4 },
    { id: 5, name: 'Asylee', value: 5 },
    { id: 6, name: 'Refugee', value: 6 },
    { id: 7, name: 'Temporary Protected Status (TPS) Holder', value: 7 },
    { id: 8, name: 'Deferred Action for Childhood Arrivals (DACA)', value: 8 },
    { id: 9, name: 'Pending Adjustment of Status', value: 9 },
    { id: 10, name: 'Other', value: 10 }
]
export const TRIP_PURPOSE_OPTIONS = [
    {
        id: 1,
        name: 'TEMP. BUSINESS PLEASURE VISITOR (B)',
        value: 'general_B',
        options: [
            { id: 'B_1', name: 'BUSINESS & TOURISM (TEMPORARY VISITOR) (B1/B2)', value: 'specific_B1B2' },
            { id: 'B_2', name: 'BUSINESS/CONFERENCE (B1)', value: 'specific_B1' },
            { id: 'B_3', name: 'TOURISM/MEDICAL TREATMENT (B2)', value: 'specific_B2' }
        ]
    },
    {
        id: 2,
        name: 'ALIEN IN TRANSIT (C)',
        value: 'general_C',
        options: [
            { id: 'C_1', name: 'CREWMEMBER IN TRANSIT (C1/D)', value: 'specific_C1D' },
            { id: 'C_2', name: 'TRANSIT (C1)', value: 'specific_C1' },
            { id: 'C_3', name: 'TRANSIT TO U.N. HEADQUARTERS (C2)', value: 'specific_C2' },
            { id: 'C_4', name: 'CHILD OF A C3 (C3)', value: 'specific_C3_child' },
            { id: 'C_5', name: 'PERSONAL EMP. OF A C3 (C3)', value: 'specific_C3_personal_emp' },
            { id: 'C_6', name: 'FOREIGN OFFICIAL IN TRANSIT (C3)', value: 'specific_C3_official' },
            { id: 'C_7', name: 'SPOUSE OF A C3 (C3)', value: 'specific_C3_spouse' },
            { id: 'C_8', name: 'NONCITIZEN IN TRANSIT LIGHTERING OP. (C4)', value: 'specific_C4' },
            { id: 'C_9', name: 'LIGHTERING CREWMEMBER IN TRANSIT (C4/D3)', value: 'specific_C4D3' }
        ]
    },
    {
        id: 3,
        name: 'CNMI WORKER OR INVESTOR (CW/E2C)',
        value: 'general_CWE2C',
        options: [
            { id: 'CW_1', name: 'CNMI TEMPORARY WORKER (CW1)', value: 'specific_CW1' },
            { id: 'CW_2', name: 'CHILD OF CW1 (CW2)', value: 'specific_CW2_child' },
            { id: 'CW_3', name: 'SPOUSE OF CW1 (CW2)', value: 'specific_CW2_spouse' },
            { id: 'CW_4', name: 'CNMI LONG TERM INVESTOR (E2C)', value: 'specific_E2C' }
        ]
    },
    {
        id: 4,
        name: 'TREATY TRADER OR INVESTOR (E)',
        value: 'general_E',
        options: [
            { id: 'E_1', name: 'CHILD OF AN E1 (E1)', value: 'specific_E1_child' },
            { id: 'E_2', name: 'EXECUTIVE/MGR/ESSENTIAL EMP (E1)', value: 'specific_E1_exec' },
            { id: 'E_3', name: 'SPOUSE OF AN E1 (E1)', value: 'specific_E1_spouse' },
            { id: 'E_4', name: 'TREATY TRADER (E1)', value: 'specific_E1_trader' },
            { id: 'E_5', name: 'CHILD OF AN E2 (E2)', value: 'specific_E2_child' },
            { id: 'E_6', name: 'EXECUTIVE/MGR/ESSENTIAL EMP (E2)', value: 'specific_E2_exec' },
            { id: 'E_7', name: 'SPOUSE OF AN E2 (E2)', value: 'specific_E2_spouse' },
            { id: 'E_8', name: 'TREATY INVESTOR (E2)', value: 'specific_E2_investor' },
            { id: 'E_9', name: 'CHILD OF AN E3 (E3D)', value: 'specific_E3D_child' },
            { id: 'E_10', name: 'SPOUSE OF AN E3 (E3D)', value: 'specific_E3D_spouse' }
        ]
    },
    {
        id: 5,
        name: 'ACADEMIC OR LANGUAGE STUDENT (F)',
        value: 'general_F',
        options: [
            { id: 'F_1', name: 'STUDENT (F1)', value: 'specific_F1' },
            { id: 'F_2', name: 'CHILD OF AN F1 (F2)', value: 'specific_F2_child' },
            { id: 'F_3', name: 'SPOUSE OF AN F1 (F2)', value: 'specific_F2_spouse' }
        ]
    },
    {
        id: 6,
        name: 'INTERNATIONAL ORGANIZATION REP./EMP. (G)',
        value: 'general_G',
        options: [
            { id: 'G_1', name: 'CHILD OF A G1 (G1)', value: 'specific_G1_child' },
            { id: 'G_2', name: 'PRINCIPAL REPRESENTATIVE (G1)', value: 'specific_G1_rep' },
            { id: 'G_3', name: 'SPOUSE OF A G1 (G1)', value: 'specific_G1_spouse' },
            { id: 'G_4', name: 'STAFF OF PRINCIPAL REPRESENTATIVE (G1)', value: 'specific_G1_staff' },
            { id: 'G_5', name: 'CHILD OF A G2 (G2)', value: 'specific_G2_child' },
            { id: 'G_6', name: 'REPRESENTATIVE (G2)', value: 'specific_G2_rep' },
            { id: 'G_7', name: 'SPOUSE OF A G2 (G2)', value: 'specific_G2_spouse' },
            { id: 'G_8', name: 'CHILD OF A G3 (G3)', value: 'specific_G3_child' },
            { id: 'G_9', name: 'NON-RECOGNIZED-MEMBER COUNTRY REP (G3)', value: 'specific_G3_rep' },
            { id: 'G_10', name: 'SPOUSE OF A G3 (G3)', value: 'specific_G3_spouse' },
            { id: 'G_11', name: 'CHILD OF AN G4 (G4)', value: 'specific_G4_child' },
            { id: 'G_12', name: 'INTERNATIONAL ORG. EMPLOYEE (G4)', value: 'specific_G4_emp' },
            { id: 'G_13', name: 'SPOUSE OF A G4 (G4)', value: 'specific_G4_spouse' },
            { id: 'G_14', name: 'CHILD OF A G5 (G5)', value: 'specific_G5_child' },
            { id: 'G_15', name: 'PERSONAL EMP. OF A G1, 2, 3, OR 4 (G5)', value: 'specific_G5_emp' },
            { id: 'G_16', name: 'SPOUSE OF A G5 (G5)', value: 'specific_G5_spouse' }
        ]
    },
    {
        id: 7,
        name: 'FOREIGN MEDIA REPRESENTATIVE (I)',
        value: 'general_I',
        options: [
            { id: 'I_1', name: 'CHILD OF AN I (I)', value: 'specific_I_child' },
            { id: 'I_2', name: 'FOREIGN MEDIA REPRESENTATIVE (I)', value: 'specific_I_rep' },
            { id: 'I_3', name: 'SPOUSE OF AN I (I)', value: 'specific_I_spouse' }
        ]
    },
    {
        id: 8,
        name: 'EXCHANGE VISITOR (J)',
        value: 'general_J',
        options: [
            { id: 'J_1', name: 'EXCHANGE VISITOR (J1)', value: 'specific_J1' },
            { id: 'J_2', name: 'CHILD OF A J1 (J2)', value: 'specific_J2_child' },
            { id: 'J_3', name: 'SPOUSE OF A J1 (J2)', value: 'specific_J2_spouse' }
        ]
    },
    {
        id: 9,
        name: 'FIANCÉ(E) OR SPOUSE OF A U.S. CITIZEN (K)',
        value: 'general_K',
        options: [
            { id: 'K_1', name: 'FIANCÉ(E) OF A U.S. CITIZEN (K1)', value: 'specific_K1' },
            { id: 'K_2', name: 'CHILD OF A K1 (K2)', value: 'specific_K2_child' },
            { id: 'K_3', name: 'SPOUSE OF A U.S. CITIZEN (K3)', value: 'specific_K3' },
            { id: 'K_4', name: 'CHILD OF A K3 (K4)', value: 'specific_K4' }
        ]
    },
    {
        id: 10,
        name: 'VOCATIONAL/NONACADEMIC STUDENT (M)',
        value: 'general_M',
        options: [
            { id: 'M_1', name: 'STUDENT (M1)', value: 'specific_M1' },
            { id: 'M_2', name: 'CHILD OF M1 (M2)', value: 'specific_M2_child' },
            { id: 'M_3', name: 'SPOUSE OF M1 (M2)', value: 'specific_M2_spouse' },
            { id: 'M_4', name: 'COMMUTER STUDENT (M3)', value: 'specific_M3' }
        ]
    },
    {
        id: 11,
        name: 'ALIEN WITH EXTRAORDINARY ABILITY (O)',
        value: 'general_O',
        options: [
            { id: 'O_1', name: 'EXTRAORDINARY ABILITY (O1)', value: 'specific_O1' },
            { id: 'O_2', name: 'ALIEN ACCOMPANYING/ASSISTING (O2)', value: 'specific_O2' },
            { id: 'O_3', name: 'CHILD OF O1 OR O2 (O3)', value: 'specific_O3_child' },
            { id: 'O_4', name: 'SPOUSE OF O1 OR O2 (O3)', value: 'specific_O3_spouse' }
        ]
    },
    {
        id: 12,
        name: 'INTERNATIONALLY RECOGNIZED ALIEN (P)',
        value: 'general_P',
        options: [
            { id: 'P_1', name: 'INTERNATIONALLY RECOGNIZED ALIEN (P1)', value: 'specific_P1' },
            { id: 'P_2', name: 'ARTIST/ENTERTAINER EXCHANGE PROG. (P2)', value: 'specific_P2' },
            { id: 'P_3', name: 'ARTIST/ENTERTAINER IN CULTURAL PROG. (P3)', value: 'specific_P3' },
            { id: 'P_4', name: 'CHILD OF P1, P2 OR P3 (P4)', value: 'specific_P4_child' },
            { id: 'P_5', name: 'SPOUSE OF P1, P2 OR P3 (P4)', value: 'specific_P4_spouse' }
        ]
    },
    {
        id: 13,
        name: 'RELIGIOUS WORKER (R)',
        value: 'general_R',
        options: [
            { id: 'R_1', name: 'RELIGIOUS WORKER (R1)', value: 'specific_R1' },
            { id: 'R_2', name: 'CHILD OF R1 (R2)', value: 'specific_R2_child' },
            { id: 'R_3', name: 'SPOUSE OF R1 (R2)', value: 'specific_R2_spouse' }
        ]
    }
]
export const WORK_STATUS_OPTIONS = [
    { id: 1, name: 'Employed Full-Time', value: 1 },
    { id: 2, name: 'Employed Part-Time', value: 2 },
    { id: 3, name: 'Self-Employed', value: 3 },
    { id: 4, name: 'Freelancer', value: 4 },
    { id: 5, name: 'Contractor', value: 5 },
    { id: 6, name: 'Intern', value: 6 },
    { id: 7, name: 'Apprentice', value: 7 },
    { id: 8, name: 'Unemployed and Looking for Work', value: 8 },
    { id: 9, name: 'Unemployed and Not Looking for Work', value: 9 },
    { id: 10, name: 'Student', value: 10 },
    { id: 11, name: 'Retired', value: 11 },
    { id: 12, name: 'Homemaker', value: 12 },
    { id: 13, name: 'Disabled and Unable to Work', value: 13 },
    { id: 14, name: 'Seasonal Worker', value: 14 },
    { id: 15, name: 'Volunteer', value: 15 },
    { id: 16, name: 'Other', value: 16 }
]

// Enum Codes Values
export const PAYMENT_STATUS_CODES = {
    '1': {
        type: 'Pending',
        icon: <IoMdTime />,
    },
    '2': {
        type: 'Success',
        icon: <IoMdCheckmarkCircleOutline />,
    },
    '3': {
        type: 'Failed',
        icon: <FaRegTimesCircle />,
    },
    '4': {
        type: 'Refunded',
        icon: <RiRefund2Fill />,
    },
}
export const US_EMBASSIES_LIST = [
    {
        id: 1,
        value: 1,
        city: 'Tirana',
        country: 'Albania',
        country_code: 'al',
    },
    {
        id: 2,
        value: 2,
        city: 'Algiers',
        country: 'Algeria',
        country_code: 'dz',
    },
    {
        id: 3,
        value: 3,
        city: 'Luanda',
        country: 'Angola',
        country_code: 'ao',
    },
    {
        id: 4,
        value: 4,
        city: 'Buenos aires',
        country: 'Argentina',
        country_code: 'ar',
    },
    {
        id: 5,
        value: 5,
        city: 'Yerevan',
        country: 'Armenia',
        country_code: 'am',
    },
    {
        id: 6,
        value: 6,
        city: 'Sydney',
        country: 'Australia',
        country_code: 'au',
    },
    {
        id: 7,
        value: 7,
        city: 'Vienna',
        country: 'Austria',
        country_code: 'at',
    },
    {
        id: 8,
        value: 8,
        city: 'Baku',
        country: 'Azerbaijan',
        country_code: 'az',
    },
    {
        id: 9,
        value: 9,
        city: 'Nassau',
        country: 'Bahamas',
        country_code: 'bs',
    },
    {
        id: 10,
        value: 10,
        city: 'Manama',
        country: 'Bahrain',
        country_code: 'bh',
    },
    {
        id: 11,
        value: 11,
        city: 'Dhaka',
        country: 'Bangladesh',
        country_code: 'bd',
    },
    {
        id: 12,
        value: 12,
        city: 'Bridgetown',
        country: 'Barbados',
        country_code: 'bb',
    },
    {
        id: 13,
        value: 13,
        city: 'Brussels',
        country: 'Belgium',
        country_code: 'be',
    },
    {
        id: 14,
        value: 14,
        city: 'Belmopan',
        country: 'Belize',
        country_code: 'bz',
    },
    {
        id: 15,
        value: 15,
        city: 'Cotonou',
        country: 'Benin',
        country_code: 'bj',
    },
    {
        id: 16,
        value: 16,
        city: 'Hamilton',
        country: 'Bermuda',
        country_code: 'bm',
    },
    {
        id: 17,
        value: 17,
        city: 'La paz',
        country: 'Bolivia',
        country_code: 'bo',
    },
    {
        id: 18,
        value: 18,
        city: 'Sarajevo',
        country: 'Bosnia-herzegovina',
        country_code: 'ba',
    },
    {
        id: 19,
        value: 19,
        city: 'Gaborone',
        country: 'Botswana',
        country_code: 'bw',
    },
    {
        id: 20,
        value: 20,
        city: 'Sao paulo',
        country: 'Brazil',
        country_code: 'br',
    },
    {
        id: 21,
        value: 21,
        city: 'Bandar seri begawan',
        country: 'Brunei',
        country_code: 'bn',
    },
    {
        id: 22,
        value: 22,
        city: 'Sofia',
        country: 'Bulgaria',
        country_code: 'bg',
    },
    {
        id: 23,
        value: 23,
        city: 'Ouagadougou',
        country: 'Burkina faso',
        country_code: 'bf',
    },
    {
        id: 24,
        value: 24,
        city: 'Rangoon',
        country: 'Burma',
        country_code: 'mm',
    },
    {
        id: 25,
        value: 25,
        city: 'Bujumbura',
        country: 'Burundi',
        country_code: 'bi',
    },
    {
        id: 26,
        value: 26,
        city: 'Praia',
        country: 'Cabo verde',
        country_code: 'cv',
    },
    {
        id: 27,
        value: 27,
        city: 'Phnom penh',
        country: 'Cambodia',
        country_code: 'kh',
    },
    {
        id: 28,
        value: 28,
        city: 'Yaounde',
        country: 'Cameroon',
        country_code: 'cm',
    },
    {
        id: 29,
        value: 29,
        city: 'Vancouver',
        country: 'Canada',
        country_code: 'ca',
    },
    {
        id: 30,
        value: 30,
        city: 'N`djamena',
        country: 'Chad',
        country_code: 'td',
    },
    {
        id: 31,
        value: 31,
        city: 'Santiago',
        country: 'Chile',
        country_code: 'cl',
    },
    {
        id: 32,
        value: 32,
        city: 'Wuhan',
        country: 'China',
        country_code: 'cn',
    },
    {
        id: 33,
        value: 33,
        city: 'Bogota',
        country: 'Colombia',
        country_code: 'co',
    },
    {
        id: 34,
        value: 34,
        city: 'Kinshasa',
        country: 'Congo',
        country_code: 'cd',
    },
    {
        id: 35,
        value: 35,
        city: 'San jose',
        country: 'Costa rica',
        country_code: 'cr',
    },
    {
        id: 36,
        value: 36,
        city: 'Abidjan',
        country: 'Cote d`ivorie',
        country_code: 'ci',
    },
    {
        id: 37,
        value: 37,
        city: 'Zagreb',
        country: 'Croatia',
        country_code: 'hr',
    },
    {
        id: 38,
        value: 38,
        city: 'Havana',
        country: 'Cuba',
        country_code: 'cu',
    },
    {
        id: 39,
        value: 39,
        city: 'Curacao',
        country: 'Curacao',
        country_code: 'cw',
    },
    {
        id: 40,
        value: 40,
        city: 'Nicosia',
        country: 'Cyprus',
        country_code: 'cy',
    },
    {
        id: 41,
        value: 41,
        city: 'Prague',
        country: 'Czech republic',
        country_code: 'cz',
    },
    {
        id: 42,
        value: 42,
        city: 'Copenhagen',
        country: 'Denmark',
        country_code: 'dk',
    },
    {
        id: 43,
        value: 43,
        city: 'Djibouti',
        country: 'Djibouti',
        country_code: 'dj',
    },
    {
        id: 44,
        value: 44,
        city: 'Santo domingo',
        country: 'Dominican republic',
        country_code: 'do',
    },
    {
        id: 45,
        value: 45,
        city: 'Quito',
        country: 'Ecuador',
        country_code: 'ec',
    },
    {
        id: 46,
        value: 46,
        city: 'Cairo',
        country: 'Egypt',
        country_code: 'eg',
    },
    {
        id: 47,
        value: 47,
        city: 'San salvador',
        country: 'El salvador',
        country_code: 'sv',
    },
    {
        id: 48,
        value: 48,
        city: 'London',
        country: 'England',
        country_code: 'gb',
    },
    {
        id: 49,
        value: 49,
        city: 'Malabo',
        country: 'Equatorial guinea',
        country_code: 'gq',
    },
    {
        id: 50,
        value: 50,
        city: 'Asmara',
        country: 'Eritrea',
        country_code: 'er',
    },
    {
        id: 51,
        value: 51,
        city: 'Tallinn',
        country: 'Estonia',
        country_code: 'ee',
    },
    {
        id: 52,
        value: 52,
        city: 'Mbabane',
        country: 'Eswatini',
        country_code: 'sz',
    },
    {
        id: 53,
        value: 53,
        city: 'Addis ababa',
        country: 'Ethiopia',
        country_code: 'et',
    },
    {
        id: 54,
        value: 54,
        city: 'Suva',
        country: 'Fiji',
        country_code: 'fj',
    },
    {
        id: 55,
        value: 55,
        city: 'Helsinki',
        country: 'Finland',
        country_code: 'fi',
    },
    {
        id: 56,
        value: 56,
        city: 'Paris',
        country: 'France',
        country_code: 'fr',
    },
    {
        id: 57,
        value: 57,
        city: 'Libreville',
        country: 'Gabon',
        country_code: 'ga',
    },
    {
        id: 58,
        value: 58,
        city: 'Banjul',
        country: 'Gambia',
        country_code: 'gm',
    },
    {
        id: 59,
        value: 59,
        city: 'Tbilisi',
        country: 'Georgia',
        country_code: 'ge',
    },
    {
        id: 60,
        value: 60,
        city: 'Munich',
        country: 'Germany',
        country_code: 'de',
    },
    {
        id: 61,
        value: 61,
        city: 'Accra',
        country: 'Ghana',
        country_code: 'gh',
    },
    {
        id: 62,
        value: 62,
        city: 'Athens',
        country: 'Greece',
        country_code: 'gr',
    },
    {
        id: 63,
        value: 63,
        city: 'Guatemala city',
        country: 'Guatemala',
        country_code: 'gt',
    },
    {
        id: 64,
        value: 64,
        city: 'Conakry',
        country: 'Guinea',
        country_code: 'gn',
    },
    {
        id: 65,
        value: 65,
        city: 'Georgetown',
        country: 'Guyana',
        country_code: 'gy',
    },
    {
        id: 66,
        value: 66,
        city: 'Port-au-prince',
        country: 'Haiti',
        country_code: 'ht',
    },
    {
        id: 67,
        value: 67,
        city: 'Tegucigalpa',
        country: 'Honduras',
        country_code: 'hn',
    },
    {
        id: 68,
        value: 68,
        city: '',
        country: 'Hong kong',
        country_code: 'hk',
    },
    {
        id: 69,
        value: 69,
        city: 'Budapest',
        country: 'Hungary',
        country_code: 'hu',
    },
    {
        id: 70,
        value: 70,
        city: 'Reykjavik',
        country: 'Iceland',
        country_code: 'is',
    },
    {
        id: 71,
        value: 71,
        city: 'New delhi',
        country: 'India',
        country_code: 'in',
    },
    {
        id: 72,
        value: 72,
        city: 'Surabaya',
        country: 'Indonesia',
        country_code: 'id',
    },
    {
        id: 73,
        value: 73,
        city: 'Erbil',
        country: 'Iraq',
        country_code: 'iq',
    },
    {
        id: 74,
        value: 74,
        city: 'Dublin',
        country: 'Ireland',
        country_code: 'ie',
    },
    {
        id: 75,
        value: 75,
        city: 'Tel aviv',
        country: 'Israel',
        country_code: 'il',
    },
    {
        id: 76,
        value: 76,
        city: 'Rome',
        country: 'Italy',
        country_code: 'it',
    },
    {
        id: 77,
        value: 77,
        city: 'Kingston',
        country: 'Jamaica',
        country_code: 'jm',
    },
    {
        id: 78,
        value: 78,
        city: 'Tokyo/sapporo',
        country: 'Japan',
        country_code: 'jp',
    },
    {
        id: 79,
        value: 79,
        city: '',
        country: 'Jerusalem',
        country_code: 'il',
    },
    {
        id: 80,
        value: 80,
        city: 'Amman',
        country: 'Jordan',
        country_code: 'jo',
    },
    {
        id: 81,
        value: 81,
        city: 'Astana',
        country: 'Kazakhstan',
        country_code: 'kz',
    },
    {
        id: 82,
        value: 82,
        city: 'Nairobi',
        country: 'Kenya',
        country_code: 'ke',
    },
    {
        id: 83,
        value: 83,
        city: 'Pristina',
        country: 'Kosovo',
        country_code: 'xk',
    },
    {
        id: 84,
        value: 84,
        city: 'Kuwait city',
        country: 'Kuwait',
        country_code: 'kw',
    },
    {
        id: 85,
        value: 85,
        city: 'Bishkek',
        country: 'Kyrgyzstan',
        country_code: 'kg',
    },
    {
        id: 86,
        value: 86,
        city: 'Vientiane',
        country: 'Laos',
        country_code: 'la',
    },
    {
        id: 87,
        value: 87,
        city: 'Riga',
        country: 'Latvia',
        country_code: 'lv',
    },
    {
        id: 88,
        value: 88,
        city: 'Beirut',
        country: 'Lebanon',
        country_code: 'lb',
    },
    {
        id: 89,
        value: 89,
        city: 'Maseru',
        country: 'Lesotho',
        country_code: 'ls',
    },
    {
        id: 90,
        value: 90,
        city: 'Monrovia',
        country: 'Liberia',
        country_code: 'lr',
    },
    {
        id: 91,
        value: 91,
        city: 'Vilnius',
        country: 'Lithuania',
        country_code: 'lt',
    },
    {
        id: 92,
        value: 92,
        city: 'Luxembourg',
        country: 'Luxembourg',
        country_code: 'lu',
    },
    {
        id: 93,
        value: 93,
        city: 'Antananarivo',
        country: 'Madagascar',
        country_code: 'mg',
    },
    {
        id: 94,
        value: 94,
        city: 'Lilongwe',
        country: 'Malawi',
        country_code: 'mw',
    },
    {
        id: 95,
        value: 95,
        city: 'Kuala lumpur',
        country: 'Malaysia',
        country_code: 'my',
    },
    {
        id: 96,
        value: 96,
        city: 'Bamako',
        country: 'Mali',
        country_code: 'ml',
    },
    {
        id: 97,
        value: 97,
        city: 'Valletta',
        country: 'Malta',
        country_code: 'mt',
    },
    {
        id: 98,
        value: 98,
        city: 'Majuro',
        country: 'Marshall islands',
        country_code: 'mh',
    },
    {
        id: 99,
        value: 99,
        city: 'Nouakchott',
        country: 'Mauritania',
        country_code: 'mr',
    },
    {
        id: 100,
        value: 100,
        city: 'Port louis',
        country: 'Mauritius',
        country_code: 'mu',
    },
    {
        id: 101,
        value: 101,
        city: 'Tijuana',
        country: 'Mexico',
        country_code: 'mx',
    },
    {
        id: 102,
        value: 102,
        city: 'Kolonia',
        country: 'Micronesia',
        country_code: 'fm',
    },
    {
        id: 103,
        value: 103,
        city: 'Chisinau',
        country: 'Moldova',
        country_code: 'md',
    },
    {
        id: 104,
        value: 104,
        city: 'Ulaanbaatar',
        country: 'Mongolia',
        country_code: 'mn',
    },
    {
        id: 105,
        value: 105,
        city: 'Podgorica',
        country: 'Montenegro',
        country_code: 'me',
    },
    {
        id: 106,
        value: 106,
        city: 'Casablanca',
        country: 'Morocco',
        country_code: 'ma',
    },
    {
        id: 107,
        value: 107,
        city: 'Maputo',
        country: 'Mozambique',
        country_code: 'mz',
    },
    {
        id: 108,
        value: 108,
        city: 'Windhoek',
        country: 'Namibia',
        country_code: 'na',
    },
    {
        id: 109,
        value: 109,
        city: 'Kathmandu',
        country: 'Nepal',
        country_code: 'np',
    },
    {
        id: 110,
        value: 110,
        city: 'Amsterdam',
        country: 'Netherlands',
        country_code: 'nl',
    },
    {
        id: 111,
        value: 111,
        city: 'Auckland',
        country: 'New zealand',
        country_code: 'nz',
    },
    {
        id: 112,
        value: 112,
        city: 'Managua',
        country: 'Nicaragua',
        country_code: 'ni',
    },
    {
        id: 113,
        value: 113,
        city: 'Niamey',
        country: 'Niger',
        country_code: 'ne',
    },
    {
        id: 114,
        value: 114,
        city: 'Lagos',
        country: 'Nigeria',
        country_code: 'ng',
    },
    {
        id: 115,
        value: 115,
        city: 'Skopje',
        country: 'North macedonia',
        country_code: 'mk',
    },
    {
        id: 116,
        value: 116,
        city: 'Belfast',
        country: 'Northern ireland',
        country_code: 'gb',
    },
    {
        id: 117,
        value: 117,
        city: 'Oslo',
        country: 'Norway',
        country_code: 'no',
    },
    {
        id: 118,
        value: 118,
        city: 'Muscat',
        country: 'Oman',
        country_code: 'om',
    },
    {
        id: 119,
        value: 119,
        city: 'Karachi',
        country: 'Pakistan',
        country_code: 'pk',
    },
    {
        id: 120,
        value: 120,
        city: 'Koror',
        country: 'Palau',
        country_code: 'pw',
    },
    {
        id: 121,
        value: 121,
        city: 'Panama city',
        country: 'Panama',
        country_code: 'pa',
    },
    {
        id: 122,
        value: 122,
        city: 'Port moresby',
        country: 'Papua new guinea',
        country_code: 'pg',
    },
    {
        id: 123,
        value: 123,
        city: 'Asuncion',
        country: 'Paraguay',
        country_code: 'py',
    },
    {
        id: 124,
        value: 124,
        city: 'Lima',
        country: 'Peru',
        country_code: 'pe',
    },
    {
        id: 125,
        value: 125,
        city: 'Manila',
        country: 'Philippines',
        country_code: 'ph',
    },
    {
        id: 126,
        value: 126,
        city: 'Warsaw',
        country: 'Poland',
        country_code: 'pl',
    },
    {
        id: 127,
        value: 127,
        city: 'Lisbon',
        country: 'Portugal',
        country_code: 'pt',
    },
    {
        id: 128,
        value: 128,
        city: 'Doha',
        country: 'Qatar',
        country_code: 'qa',
    },
    {
        id: 129,
        value: 129,
        city: 'Bucharest',
        country: 'Romania',
        country_code: 'ro',
    },
    {
        id: 130,
        value: 130,
        city: 'Moscow',
        country: 'Russia',
        country_code: 'ru',
    },
    {
        id: 131,
        value: 131,
        city: 'Kigali',
        country: 'Rwanda',
        country_code: 'rw',
    },
    {
        id: 132,
        value: 132,
        city: 'Apia',
        country: 'Samoa',
        country_code: 'ws',
    },
    {
        id: 133,
        value: 133,
        city: 'Riyadh',
        country: 'Saudi arabia',
        country_code: 'sa',
    },
    {
        id: 134,
        value: 134,
        city: 'Dakar',
        country: 'Senegal',
        country_code: 'sn',
    },
    {
        id: 135,
        value: 135,
        city: 'Belgrade',
        country: 'Serbia',
        country_code: 'rs',
    },
    {
        id: 136,
        value: 136,
        city: 'Freetown',
        country: 'Sierra leone',
        country_code: 'sl',
    },
    {
        id: 137,
        value: 137,
        city: 'Singapore',
        country: 'Singapore',
        country_code: 'sg',
    },
    {
        id: 138,
        value: 138,
        city: 'Bratislava',
        country: 'Slovakia',
        country_code: 'sk',
    },
    {
        id: 139,
        value: 139,
        city: 'Ljubljana',
        country: 'Slovenia',
        country_code: 'si',
    },
    {
        id: 140,
        value: 140,
        city: 'Johannesburg',
        country: 'South africa',
        country_code: 'za',
    },
    {
        id: 141,
        value: 141,
        city: 'Seoul',
        country: 'South korea',
        country_code: 'kr',
    },
    {
        id: 142,
        value: 142,
        city: 'Juba',
        country: 'South sudan',
        country_code: 'ss',
    },
    {
        id: 143,
        value: 143,
        city: 'Madrid',
        country: 'Spain',
        country_code: 'es',
    },
    {
        id: 144,
        value: 144,
        city: 'Colombo',
        country: 'Sri lanka',
        country_code: 'lk',
    },
    {
        id: 145,
        value: 145,
        city: 'Paramaribo',
        country: 'Suriname',
        country_code: 'sr',
    },
    {
        id: 146,
        value: 146,
        city: 'Stockholm',
        country: 'Sweden',
        country_code: 'se',
    },
    {
        id: 147,
        value: 147,
        city: 'Bern',
        country: 'Switzerland',
        country_code: 'ch',
    },
    {
        id: 148,
        value: 148,
        city: 'Taipei',
        country: 'Taiwan',
        country_code: 'tw',
    },
    {
        id: 149,
        value: 149,
        city: 'Dushanbe',
        country: 'Tajikistan',
        country_code: 'tj',
    },
    {
        id: 150,
        value: 150,
        city: 'Dar es salaam',
        country: 'Tanzania',
        country_code: 'tz',
    },
    {
        id: 151,
        value: 151,
        city: 'Chiang mai',
        country: 'Thailand',
        country_code: 'th',
    },
    {
        id: 152,
        value: 152,
        city: 'Dili',
        country: 'Timor leste',
        country_code: 'tl',
    },
    {
        id: 153,
        value: 153,
        city: 'Lome',
        country: 'Togo',
        country_code: 'tg',
    },
    {
        id: 154,
        value: 154,
        city: 'Port of spain',
        country: 'Trinidad',
        country_code: 'tt',
    },
    {
        id: 155,
        value: 155,
        city: 'Tunis',
        country: 'Tunisia',
        country_code: 'tn',
    },
    {
        id: 156,
        value: 156,
        city: 'Istanbul',
        country: 'Turkey',
        country_code: 'tr',
    },
    {
        id: 157,
        value: 157,
        city: 'Ashgabat',
        country: 'Turkmenistan',
        country_code: 'tm',
    },
    {
        id: 158,
        value: 158,
        city: '',
        country: 'U.s. - domestic',
        country_code: 'us',
    },
    {
        id: 159,
        value: 159,
        city: 'Kampala',
        country: 'Uganda',
        country_code: 'ug',
    },
    {
        id: 160,
        value: 160,
        city: 'Kyiv',
        country: 'Ukraine',
        country_code: 'ua',
    },
    {
        id: 161,
        value: 161,
        city: 'Dubai',
        country: 'United arab emirates',
        country_code: 'ae',
    },
    {
        id: 162,
        value: 162,
        city: 'Montevideo',
        country: 'Uruguay',
        country_code: 'uy',
    },
    {
        id: 163,
        value: 163,
        city: 'Tashkent',
        country: 'Uzbekistan',
        country_code: 'uz',
    },
    {
        id: 164,
        value: 164,
        city: 'Ho chi minh city',
        country: 'Vietnam',
        country_code: 'vn',
    },
    {
        id: 165,
        value: 165,
        city: 'Lusaka',
        country: 'Zambia',
        country_code: 'zm',
    },
    {
        id: 166,
        value: 166,
        city: 'Harare',
        country: 'Zimbabwe',
        country_code: 'zw',
    },
]
