import { createSlice } from '@reduxjs/toolkit';

const initialState = false

const loadingStatusSlice = createSlice({
    name: 'loadingStatus',
    initialState,
    reducers: {
        setLoadingStatus: (state, action) => {
            state.loadingStatus = action.payload;
        },
    }
})

export const { setLoadingStatus } = loadingStatusSlice.actions;
export default loadingStatusSlice.reducer;
